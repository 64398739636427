import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Components
import { Title } from "src/components/typography"
import { Container } from "src/components/common"
import Service from "./service.component"

// Assets
import ServicesIllustration from "images/Services-Illustration.svg"

// Styles
import Styled from "./services.styled"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(services)/" } }
      ) {
        ...Services
      }
    }
  `)

  const services = data.allMarkdownRemark.edges[0].node.frontmatter.services

  const renderServices = () =>
    services.map(({ name, details }, index) => {
      return <Service key={`${name}-${index}`} service={name} text={details} />
    })

  return (
    <Styled.Services>
      <Container>
        <Title level={2} mb={50} blue>
          Services and Capabilities
        </Title>

        <div>{renderServices()}</div>

        <Styled.Illustration
          src={ServicesIllustration}
          alt="Sitting woman working with web and graphs"
        />
      </Container>
    </Styled.Services>
  )
}

export default Services
