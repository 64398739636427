import styled from "styled-components"
import Colors from "src/ui/colors"

const Styled = {
  Service: styled.div`
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-size: 24px;
      line-height: 40px;
      color: ${Colors.darkGray};
      margin-top: 25px;
      max-width: 600px;
      width: 100%;
    }
  `,

  Heading: styled.div`
    display: flex;
    align-items: center;
  `,

  ToggleButton: styled.button`
    align-self: flex-start;
    padding: 0;
    border: none;
    background: none;
    margin: 12px 25px 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  `,

  Chevron: styled.img`
    display: block;
    margin-right: 10px;
  `,

  Details: styled.p`
    margin-left: 32px;
  `,
}

export default Styled
