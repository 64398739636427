import styled from "styled-components"

const Styled = {
  Services: styled.section`
    padding: 100px;
    position: relative;

    @media (max-width: 950px) {
      padding: 50px 35px 350px;
    }
  `,

  Illustration: styled.img`
    position: absolute;
    bottom: -100px;
    right: 25px;
    height: 500px;
    width: auto;

    @media (max-width: 950px) {
      bottom: -350px;
      height: 300px;
    }
  `,
}

export default Styled
