import React from "react"

// Components
import { Footer, Layout, SEO } from "components/common"
import { Services } from "components/services-page"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <Services />
    <Footer heading="Let’s discuss how we can help you" />
  </Layout>
)

export default ServicesPage
