import React, { useState, useCallback } from "react"

// Components
import { Title } from "src/components/typography"

// Assets
import ChevronRight from "images/icons/chevron-right.svg"
import ChevronDown from "images/icons/chevron-down.svg"

// Styles
import Styled from "./service.styled"

// Types
interface Props {
  service: string
  text: string
}

const Service = ({ service, text }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  // ?: Is this right
  const handleClick = useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [setIsOpen])

  return (
    <Styled.Service>
      <Styled.ToggleButton
        aria-label={`Read more about ${service}`}
        onClick={handleClick}
      >
        <Styled.Chevron
          src={isOpen ? ChevronDown : ChevronRight}
          alt="Blue arrow"
        />
        <Title level={4}>{service}</Title>
      </Styled.ToggleButton>

      {isOpen && <Styled.Details>{text}</Styled.Details>}
    </Styled.Service>
  )
}

export default Service
